var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"container",staticStyle:{"height":"60px","width":"1100px"}},[_c('dashboard-title',{staticClass:"font  font-md text-uppercase"})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"d-flex flex-wrap justify-space-around mb-6",attrs:{"color":"transparent","flat":""}},[_c('information-card',{attrs:{"description":"All Staffs","total":_vm.totalCount.staffCount}}),_c('information-card',{attrs:{"description":"Members","total":_vm.totalCount.membersCount}}),_c('information-card',{attrs:{"description":"New Members Today","total":_vm.totalCount.totalMembersToday}}),_c('information-card',{attrs:{"description":"Overall Transactions","total":_vm.totalCount.totalTransactions}})],1)],1),_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('h4',{staticClass:" font font-md text-uppercase mx-6 pb-1"},[_vm._v(" Newly Added Members Today ")]),_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize font font-md mx-6",attrs:{"items":_vm.members,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.paginate.total,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [20, 30, 40],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])]}},{key:"item.birthDate",fn:function(ref){
        var item = ref.item;
return [(item.birthDate)?_c('v-chip',{staticClass:" font font-md font-weight-bold",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.birthDate))+" ")]):_c('v-chip',{staticClass:" font font-md font-weight-bold",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s('No Date Of Birth')+" ")])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }